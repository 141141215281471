import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import React, { Fragment, useEffect, useState } from "react";

let toggleProfessionalForm = () => { }

const HubspotProfessionalForm = props => {

    const [open, setOpen] = useState(false);
    const { region, portalId, formId } = props;

    toggleProfessionalForm = (value) => {
        // console.log('toggleProfessionalForm');
        setOpen(value);
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            // console.log('window.hbspt', window.hbspt);
            // get element by id
            // const formDiv = document.getElementById('hubspotProfessionalForm')
            // console.log('formDiv', formDiv);


            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: region,
                    portalId: portalId,
                    formId: formId,
                    target: '#hubspotProfessionalForm'
                })
            }
        });
    }

    useEffect(() => {
        // const script = document.createElement('script');
        // script.src = 'https://js.hsforms.net/forms/shell.js';
        // document.body.appendChild(script);

        // script.addEventListener('load', () => {
        //     // @TS-ignore
        //     console.log('window.hbspt', window.hbspt);
        //     // get element by id
        //     const formDiv = document.getElementById('hubspotProfessionalForm')
        //     console.log('formDiv', formDiv);


        //     if (window.hbspt) {
        //         // @TS-ignore
        //         window.hbspt.forms.create({
        //             region: region,
        //             portalId: portalId,
        //             formId: formId,
        //             target: '#hubspotProfessionalForm'
        //         })
        //     }
        // });
    }, []);

    return (
        <Transition.Root show={open} as={Fragment}>
            <div as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex justify-center min-h-full items-end p-4 sm:items-center sm:p-0 ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 ">
                                <div className="space-y-6 sm:space-y-5">
                                    <div className="shadow sm:overflow-hidden sm:rounded-md">
                                        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                                            <div className="absolute top-0 right-0 m-3">
                                                <button
                                                    type="button"
                                                    clnpmme="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    onClick={() =>
                                                        toggleProfessionalForm(false)
                                                    }
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>

                                            <h1 class="col-span-4 text-2xl text-center font-extrabold text-gray-900 tracking-tight mb-2 sm:text-4xl">
                                                Professional onboarding form
                                            </h1>
                                            <div className="flex justify-center min-h-[200px] sm:min-h-[400px]">
                                                <div className="grid grid-cols-12 gap-6 self-center">
                                                    <div className="col-start-2 col-span-10" id="hubspotProfessionalForm"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Transition.Child>
                    </div>
                </div>


            </div >


        </Transition.Root >
    );
};

export { toggleProfessionalForm }
export default HubspotProfessionalForm;