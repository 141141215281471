import * as React from "react"
import { useState } from "react";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline"
import { Carousel } from "react-responsive-carousel"


const NewTestimonials = ({ title, basicTitle, basicSubTitle, testimonials }) => {
  const [testimonialCurrentSlide, setTestimonialCurrentSlide] = useState(0)

  function testimonialPrev() {
    const newSlideIndex = testimonialCurrentSlide - 1
    setTestimonialCurrentSlide(newSlideIndex)
  }

  function testimonialNext() {
    const newSlideIndex = testimonialCurrentSlide + 1
    setTestimonialCurrentSlide(newSlideIndex)
  }

  function testimonialUpdateCurrentSlide(index) {
    if (testimonialCurrentSlide != index) {
      setTestimonialCurrentSlide(index)
    }
  }

  return (
    <div className="relative py-12">
      <div className="mx-auto max-w-md px-2 sm:px-4 sm:max-w-3xl lg:max-w-7xl">
        <div className="">
          <a className="no-underline inline-flex space-x-4">
            <span className="rounded bg-green-50 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide">
              {title ?? "Testimonials"}
            </span>
          </a>
          {testimonials.length > 1 &&
            <><a
              onClick={() => testimonialPrev()}
              className="no-underline cursor-pointer ml-4 inline-flex space-x-4"
            >
              <span className="rounded bg-green-50 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide uppercase">
                <ChevronLeftIcon
                  className="h-4 w-4 inline-block"
                  aria-hidden="true" />
              </span>
            </a><a
              onClick={() => testimonialNext()}
              className="no-underline ml-2 cursor-pointer inline-flex space-x-4"
            >
                <span className="rounded bg-green-50 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide uppercase">
                  <ChevronRightIcon
                    className="h-4 w-4 inline-block"
                    aria-hidden="true" />
                </span>
              </a></>
          }

        </div>
        {basicTitle && <div className="  text-slate-800 text-3xl  md:text-5xl font-medium  leading-[60px] ">{basicTitle}</div>}
        {basicSubTitle && <div className=" text-slate-500 text-xl font-medium  leading-[30px]">{basicSubTitle}</div>}
        <Carousel
          centerMode={true}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          selectedItem={testimonialCurrentSlide}
          onChange={(index) => testimonialUpdateCurrentSlide(index)}
          showArrows={false}
        >
          {/* foreach for each testimonial */}
          {testimonials.map((testimonial, iv) => {
            return (
              <>
                <div key={iv} className="p-8 pl-2">
                  <div className="grid grid-cols-12 bg-white shadow-lg  rounded-2xl">
                    <div className="hidden sm:block col-span-4 relative rounded-tl-2xl rounded-bl-2xl">
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="rounded-tl-2xl rounded-bl-2xl"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-8 text-left min-h-[450px] sm:min-h-0 relative">
                      {
                        testimonial.quotes.map((quote, i) => {
                          return (
                            <p className="text-base sm:text-lg m-4 italic">
                              {quote}
                            </p>
                          );
                        })
                      }
                      <div className="absolute sm:relative lg:absolute left-4 bottom-2">
                        <span className="text-base sm:text-lg font-semibold block">
                          {testimonial.name}
                        </span>
                        <span className="text-base block text-gray-500">
                          {testimonial.company}
                        </span>
                      </div>
                      <div className="hidden sm:block absolute right-4 bottom-2 ">
                        <img
                          src={testimonial.logo}
                          alt={testimonial.company}
                          className=""
                          style={{
                            height: testimonial?.logoStyle?.height ? testimonial?.logoStyle?.height : 'auto',
                            width: testimonial?.logoStyle?.width ? testimonial?.logoStyle?.width : 'auto'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </Carousel >
      </div>
    </div>
  )
}

export default NewTestimonials
