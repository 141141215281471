import { Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import * as React from "react"
import { Fragment, useState } from "react"
import {
  postProfessionalOnboardingForm,
  sendEmail,
} from "../services/api-endpoints"
import { getGoogleRecaptchaSecret, getGoogleRecaptchaSiteKey, hubspotSonjaMeetingLink } from "../services/constant"
import ApiLoader from "./api-loader"
import NotificationPopupModal, {
  toggleNotificationPopup,
} from "./notification-poup-component"
import ReCAPTCHA from "react-google-recaptcha"

let numberOfSelectedProjectType = 0
let toggleProfessionalOnboardingForm = () => { }

const ProfessionalInfoForm = ({ afterSubmit }) => {
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [phone, setPhone] = useState("")
  const [companyEmail, setCompanyEmail] = useState("")
  const [professionalHighlights, setProfessionalHighlights] = useState([])
  const [website, setWebsite] = useState("")
  const [professionalOtherInfo, setProfessionalOtherInfo] = useState("")
  const [open, setOpen] = useState(false)
  const [phoneInvalid, setPhoneInvalid] = useState(false)
  const [captchaVerified, setCaptchaVerified] = useState(false);

  toggleProfessionalOnboardingForm = showVal => {
    setOpen(showVal)
  }

  let inputFormData = [
    {
      idOrName: "first-name",
      title: "First name",
      type: "text",
      required: true,
      stateVariable: firstName,
    },
    {
      idOrName: "last-name",
      title: "Last name",
      type: "text",
      required: true,
      stateVariable: lastName,
    },
    {
      idOrName: "company-name",
      title: "Company name",
      type: "text",
      required: true,
      stateVariable: companyName,
    },
    {
      idOrName: "phone",
      title: "Phone",
      type: "tel",
      required: true,
      stateVariable: phone,
    },
    {
      idOrName: "company-email",
      title: "Email",
      type: "email",
      required: true,
      stateVariable: companyEmail,
    },
    {
      idOrName: "professional-highlights",
      type: "checkbox",
      title:
        "Please select what type of projects you manage or overlook",
      subtitle:
        "",
      options: [
        { title: "Have been planning/managing at least two 7+ Star Energy efficient rated new builds or renovations projects" },
        {
          title:
            "Have been planning/managing at least two projects that received Passive house certification",
        },
        {
          title:
            "Have been planning/managing at least two projects within other design/certification/building concepts like ActiveHouse, Living Building Challenge, Home Biology or others",
        },
      ],
      required: true,
      stateVariable: professionalHighlights,
    },
    {
      idOrName: "professional-other-info",
      title: "Others",
      subtitle: "(You can further specify your projects down here. Please provide the link to the projects if available)",
      type: "textarea",
      required: false,
      stateVariable: professionalOtherInfo,
    },
    {
      idOrName: "website",
      title: "Website",
      type: "text",
      required: false,
      stateVariable: website,
    },

  ]

  function resetFormData() {
    setFirstName("")
    setLastName("")
    setCompanyEmail("")
    setProfessionalHighlights([])
    setCompanyName("")
    setPhone("")
    setWebsite("")
    setProfessionalOtherInfo("")
  }

  function shouldSubmitForm() {
    if (!validatePhone()) {
      return "phone"
    }

    if (professionalHighlights.length < 1) {
      return "professionalHighlights"
    }

    if (!captchaVerified) {
      return "captcha"
    }

    return true
  }

  function sendEmailToProfessional() {
    const meetingBookingLink =
      hubspotSonjaMeetingLink() +
      "?embed=false&firstName=" +
      firstName +
      "&lastName=" +
      lastName +
      "&email=" +
      companyEmail +
      "&phone=" +
      phone
    const meetingBookingHTMLContent =
      "<a class='mcnButton' title='Book your appointment' href='" +
      meetingBookingLink +
      "'target='_blank' style='font-weight: bold;letter-spacing: normal;line-height: 100%;text-align: center;text-decoration: none;color: #FFFFFF;'>Book Your Appointment</a>"
    const data = {
      to: companyEmail,
      from: "sonja@evitat.com.au",
      subject: "Evitat: Thank you, we have received your enquiry",
      template_name: "seller-book-your-appointment",
      template_content: [
        {
          name: "user_name",
          content: firstName + " " + lastName,
        },
        {
          name: "book_button",
          content: meetingBookingHTMLContent,
        },
      ],
    }

    sendEmail(data).then(res => {
      if (res?.data?.code === 200) {
        return true
      }

      return false
    })
  }

  function postProfessionalInfo(event) {
    event.preventDefault()

    const shouldSubmitStatus = shouldSubmitForm()

    if (shouldSubmitStatus === true) {
      const data = {
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
        phone: phone,
        email: companyEmail,
        project_type: professionalHighlights,
        website: website,
        comments: professionalOtherInfo,
      }

      postProfessionalOnboardingForm(data).then(res => {
        if (res?.data?.code === 200) {
          sendEmailToProfessional()
          resetFormData()
          setOpen(false)
          afterSubmit(true, data)
        } else {
          setOpen(false)
          afterSubmit(false)
        }
      })
    } else {
      if (shouldSubmitStatus === "phone") {
        toggleNotificationPopup(
          true,
          "Uh oh!",
          "Please enter a valid Australian phonenumber",
          "failure"
        )
      } else if (shouldSubmitStatus === "professionalHighlights") {
        toggleNotificationPopup(
          true,
          "Uh oh!",
          "Please select at least one type of project",
          "failure"
        )
      }
      else if (shouldSubmitStatus === "captcha") {
        toggleNotificationPopup(
          true,
          "Uh oh!",
          "Please complete the captcha",
          "failure"
        )
      }
    }
  }

  function stateVariableSetter(type, value) {
    if (type === "first-name") setFirstName(value)

    if (type === "last-name") setLastName(value)

    if (type === "company-name") setCompanyName(value)

    if (type === "phone") setPhone(value)

    if (type === "company-email") setCompanyEmail(value)

    if (type === "website") setWebsite(value)

    if (type === "professional-other-info")
      setProfessionalOtherInfo(value)
  }

  const handleSaveButtonStatus = event => {
    let localProjectType = professionalHighlights

    if (event.target.checked) {
      localProjectType.push(event.target.value)
      setProfessionalHighlights(localProjectType)

      numberOfSelectedProjectType += 1
    } else {
      setProfessionalHighlights(localProjectType.filter(cb => cb !== event.target.value))

      numberOfSelectedProjectType -= 1
    }

    if (numberOfSelectedProjectType > 1) {
      // setDisableSubmit(false)
    } else {
      // setDisableSubmit(true)
    }
  }

  function changeCaptchaVerificationStatus(resToken) {
    return fetch(`/.netlify/functions/verify-captcha-token?secret=` + getGoogleRecaptchaSecret() + `&response=` + resToken, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(result => result.json())
      .then(result => {
        if (result.success === true) {
          setCaptchaVerified(true)
        }
        // return false;
      })
      .catch((err) => console.log('err', err));
  }

  function validatePhone(event) {
    const auPhoneRegex =
      /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
    const matchedArray = phone.match(auPhoneRegex)

    if (!matchedArray || matchedArray.length < 1) {
      setPhoneInvalid(true)
      // setDisableSubmit(true)
      return false
    } else {
      setPhoneInvalid(false)
      // setDisableSubmit(false)
      return true
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <div as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex justify-center min-h-full items-end p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative sticky z-10 space-y-8 divide-y divide-gray-200 sm:my-8 sm:mx-8 xl:mx-72 md:mx-44"> {/** xl:mx-72 md:mx-44 my-8 mx-8 */}
                <form
                  onSubmit={event => postProfessionalInfo(event)}
                  name="professional-enquiry-form"
                  id="professional-enquiry-form"
                >
                  <ApiLoader />
                  <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                      <div className="shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                          <div className="absolute top-0 right-0 m-3">
                            <button
                              type="button"
                              clnpmme="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() =>
                                toggleProfessionalOnboardingForm(false)
                              }
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                          <h1 class="col-span-4 text-2xl text-center font-extrabold text-gray-900 tracking-tight mb-2 sm:text-4xl">
                            Professional enquiry form
                          </h1>

                          <div></div>

                          <div className="grid grid-cols-12 gap-6">
                            {inputFormData.map(input => {
                              if (
                                input.type === "text" ||
                                input.type === "email" ||
                                input.type === "url"
                              ) {
                                return (
                                  <div
                                    className="col-span-12"
                                    key={input.idOrName}
                                  >
                                    <label
                                      htmlFor={input.idOrName}
                                      className="block text-base font-medium text-gray-700 "
                                    >
                                      {input.title}{" "}
                                      {input.required ? (
                                        <span className="text-red-400">*</span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                    <span className="mt-4 text-sm">
                                      {input.subtitle}
                                    </span>
                                    <div className="mt-1">
                                      <input
                                        type={input.type}
                                        pattern={input.pattern}
                                        value={input.stateVariable}
                                        onChange={textBoxVal =>
                                          stateVariableSetter(
                                            input.idOrName,
                                            textBoxVal?.target?.value
                                          )
                                        }
                                        required={input.required}
                                        name={input.idOrName}
                                        id={input.idOrName}
                                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                )
                              }

                              if (
                                input.type === "textarea"
                              ) {
                                return (
                                  <div
                                    className="col-span-12"
                                    key={input.idOrName}
                                  >
                                    <label
                                      htmlFor={input.idOrName}
                                      className="block text-base font-medium text-gray-700 "
                                    >
                                      {input.title}{" "}
                                      {input.required ? (
                                        <span className="text-red-400">*</span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                    <span className="mt-4 text-sm">
                                      {input.subtitle}
                                    </span>
                                    <div className="mt-1">
                                      <textarea
                                        value={input.stateVariable}
                                        onChange={textBoxVal =>
                                          stateVariableSetter(
                                            input.idOrName,
                                            textBoxVal?.target?.value
                                          )
                                        }
                                        required={input.required}
                                        name={input.idOrName}
                                        id={input.idOrName}
                                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                )
                              }

                              if (input.type === "tel") {
                                return (
                                  <div
                                    key={input.idOrName}
                                    className="col-span-12"
                                  >
                                    <label
                                      htmlFor={input.idOrName}
                                      className="block text-base font-medium text-gray-700"
                                    >
                                      {input.title}{" "}
                                      {input.required ? (
                                        <span className="text-red-400">*</span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                    <div className="col-span-10 mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        // pattern="^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$"
                                        value={input.stateVariable}
                                        onChange={textBoxVal =>
                                          stateVariableSetter(
                                            input.idOrName,
                                            textBoxVal?.target?.value
                                          )
                                        }
                                        onKeyUp={event => validatePhone(event)}
                                        type="text"
                                        required={input.required}
                                        name={input.idOrName}
                                        id={input.idOrName}
                                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                      />
                                      {phoneInvalid && (
                                        <span className="text-xs text-red-400">
                                          <i>
                                            Please enter valid Australian phone
                                            number
                                          </i>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )
                              }

                              if (input.type === "checkbox") {
                                return (
                                  <div
                                    key={input.idOrName}
                                    className="col-span-12 sm:col-span-12"
                                  >
                                    <span className="text-base">
                                      {input.title}{" "}
                                      {input.required ? (
                                        <span className="text-red-400">*</span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    <br></br>
                                    <label hidden={true}>
                                      Company product benefits
                                    </label>
                                    <div className="mt-2">
                                      {input.options.map((option, index) => (
                                        <div
                                          key={option.idOrName}
                                          className="relative flex items-start py-1"
                                        >
                                          <div className="ml-3 flex h-5 items-center">
                                            <input
                                              onChange={handleSaveButtonStatus}
                                              id={`option-${index}`}
                                              name={`option-${index}`}
                                              value={option.title}
                                              type="checkbox"
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                          </div>
                                          <div className="ml-2 min-w-0 flex-1 text-sm">
                                            <label
                                              htmlFor={`option-${index}`}
                                              className="select-none font-medium text-gray-700 cursor-pointer"
                                            >
                                              {option.title}
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )
                              }
                            })}
                          </div>

                          <div className="px-4 py-3 text-center sm:px-6">
                            <ReCAPTCHA
                              className="flex justify-center"
                              sitekey={getGoogleRecaptchaSiteKey()} onChange={event => changeCaptchaVerificationStatus(event)} onExpired={event => setCaptchaVerified(false)} />
                            <button
                              type="submit"
                              disabled={disableSubmit}
                              className="mt-4 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            // onClick={saveData}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition.Root>
  )
}

export { toggleProfessionalOnboardingForm }
export default ProfessionalInfoForm
